import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatabase, ref, onValue, set as firebaseSet } from "firebase/database";

export function RestaurantIndexNoFooterMain(props) {
    const [customerList, setCustomerList] = useState();
    const [customerID, setCustomerID] = useState("");
    const [newPoints, setNewPoints] = useState("");

    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();
            const restaurantCustomersRef = ref(db, "restaurantCustomers/" + props.currentRestaurant.uid);

            const unregisterFunction = onValue(restaurantCustomersRef, (snap) => {
                if (snap.exists()) {
                    const allCustomersObjects = snap.val();

                    const allCustomersKeys = Object.keys(allCustomersObjects);

                    const allCustomersArray = allCustomersKeys.map((key) => {
                        const singleCustomerCopy = { ...allCustomersObjects[key] };
                        singleCustomerCopy.key = key;
                        singleCustomerCopy.val = allCustomersObjects[key];
                        return singleCustomerCopy;
                    });

                    setData(allCustomersArray);
                    setLoader(false);
                } else {
                    setData([])
                    setLoader(false);
                }

            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.currentRestaurant.uid])

    const setData = (data) => {
        setCustomerList(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (customerList.length === 0) {
            toast.error('No customers in the database', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        } else {
            let isCustomerID = false;

            let currentCustomerPoints = 0;
            let currentCustomerKey = "";

            for (let i = 0; i < customerList.length; i++) {
                if (customerList[i].cusID === customerID.toLowerCase()) {
                    isCustomerID = true;
                    currentCustomerPoints = customerList[i].points;
                    currentCustomerKey = customerList[i].key;
                }
            }

            if (!isCustomerID) {
                toast.error('Please enter valid customer ID', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
            else if (parseInt(newPoints) < 0 || newPoints === "") {
                toast.error('Please enter valid number of points', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
            else {
                const db = getDatabase();

                const customerPointsRef = ref(db, "customerRestaurants/" + currentCustomerKey + "/" + props.currentRestaurant.uid + "/points");
                const restaurantPointsRef = ref(db, "restaurantCustomers/" + props.currentRestaurant.uid + "/" + currentCustomerKey + "/points");

                firebaseSet(customerPointsRef, parseInt(currentCustomerPoints) + parseInt(newPoints));
                firebaseSet(restaurantPointsRef, parseInt(currentCustomerPoints) + parseInt(newPoints));

                setCustomerID("");
                setNewPoints("");

                toast.success('Points added successfully!!!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
        }
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        <main className='no-footer-main-container'>
                            <div className='no-footer-main-item'>
                                <h1 className='heading'>BEHEER for</h1>
                                <h1 className='heading'>{props.currentRestaurantName}</h1>
                                <h1 className='desktop-heading'>BEHEER for <span className='color-red'>{props.currentRestaurantName}</span></h1>
                            </div>

                            <div className='no-footer-main-item'>
                                <form className='add-points-container' autoComplete="off" onSubmit={handleSubmit}>
                                    <div className='add-points-item'>
                                        <label className='visually-hidden' htmlFor='customer-id'>Customer ID:</label>
                                        <input
                                            type='text'
                                            id='customer-id'
                                            name='customer-id'
                                            placeholder='Customer ID'
                                            value={customerID}
                                            onChange={(e) => setCustomerID(e.target.value)}
                                        />
                                    </div>

                                    <div className='add-points-item'>
                                        <label className='visually-hidden' htmlFor='add-points'>Number of Points:</label>
                                        <input
                                            type='number'
                                            id='add-points'
                                            name='add-points'
                                            placeholder='Number of Points'
                                            value={newPoints}
                                            onChange={(e) => setNewPoints(e.target.value)}
                                        />
                                    </div>

                                    <div className='add-points-item'>
                                        <Button id='enter-button' text='Enter' />
                                    </div>
                                </form>

                                <Link to='/my-restaurant/approve-rewards'>
                                    <button id='link-to-approve-rewards-button'>Approve Rewards</button>
                                </Link>
                            </div>
                        </main>
                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                    </>
                )
            }
        </>
    )
}