import React, { useState, useEffect } from "react";
import { MobileHeader } from "./MobileHeader";
import { DesktopHeader } from "./DesktopHeader";
import { RestaurantIndexNoFooterMain } from "./RestaurantIndexNoFooterMain";
import { getDatabase, ref, onValue } from "firebase/database";

export function RestaurantIndex(props) {
    const [currentRestaurantName, setCurrentRestaurantName] = useState("");

    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();
            const restaurantNameRef = ref(db, "restaurants/" + props.currentRestaurant.uid + "/restaurantName");

            const unregisterFunction = onValue(restaurantNameRef, (snap) => {
                if (snap.exists()) {
                    setData(snap.val());
                    setLoader(false);
                } else {
                    setData("")
                    setLoader(false);
                }

            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.currentRestaurant.uid])

    const setData = (data) => {
        setCurrentRestaurantName(data);
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        <MobileHeader />
                        <DesktopHeader />
                        <RestaurantIndexNoFooterMain currentRestaurantName={currentRestaurantName} currentRestaurant={props.currentRestaurant} />
                    </>
                )
            }
            {/* <MobileHeader />
            <DesktopHeader />
            <RestaurantIndexNoFooterMain currentRestaurantName={currentRestaurantName} currentRestaurant={props.currentRestaurant} /> */}
        </>
    )
}