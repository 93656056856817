import React from "react";
import { NavLink } from "react-router-dom";

export function LandingPageDesktopHeader() {
    return (
        <>
            <header className='desktop-header-container'>
                <div className='desktop-header-item'>
                    {/* <p className='logo'>BEHEER</p> */}
                    <img src={require('./../imgs/beheerLogo.png')} alt='forward arrow' id='logo' />
                </div>

                <div className='desktop-header-item'>
                    <nav className='desktop-navigation'>
                        <ul>
                            <li>Solutions</li>
                            <li>Pricing</li>
                            <li>Contact Us</li>
                            <li>Follow Us</li>
                        </ul>
                    </nav>
                </div>

                <div className='desktop-header-item'>
                    <NavLink to='/login'><button id='sign-in-button'>Sign In</button></NavLink>
                </div>
            </header>
        </>
    )
}