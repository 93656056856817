import React from "react";
import { SearchResult } from "./SearchResult";

export function SearchResultList(props) {
    return (
        <>
            <div className='results-list'>
                {props.results.map((result, id) => {
                    return <SearchResult result={result.name} key={id} />
                })}
            </div>
        </>
    )
}