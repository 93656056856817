import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { getDatabase, ref, set as firebaseSet, onValue } from "firebase/database";

export function DeleteWindow(props) {
    const [isUnapproved, setIsUnapproved] = useState(false);

    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const unapprovedRewardsRef = ref(db, "unapprovedRewards/" + props.deleteRestaurant.key);

            let isCustomerInUnapprovedList = false;

            const unregisterFunction = onValue(unapprovedRewardsRef, (snap) => {
                if (snap.exists()) {
                    const allUnapprovedRewardsObjects = snap.val();

                    const allUnapprovedRewardsKeys = Object.keys(allUnapprovedRewardsObjects);

                    for (let i = 0; i < allUnapprovedRewardsKeys.length; i++) {
                        if (allUnapprovedRewardsKeys[i] === props.customerID) {
                            isCustomerInUnapprovedList = true;
                        }
                    }

                    if (isCustomerInUnapprovedList) {
                        setData(true)
                        setLoader(false);
                    } else {
                        setData(false)
                        setLoader(false);
                    }
                } else {
                    setData(false);
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.customerID, props.deleteRestaurant.key])

    const setData = (data) => {
        setIsUnapproved(data)
    }

    const onConfirm = () => {
        const cusID = props.customerID;
        const resID = props.deleteRestaurant.key;

        const db = getDatabase();
        const customerRestaurantsRef = ref(db, "customerRestaurants/" + cusID + "/" + resID);
        const restaurantCustomersRef = ref(db, "restaurantCustomers/" + resID + "/" + cusID);

        firebaseSet(customerRestaurantsRef, null)
            .then(() => {
                console.log("success!");
            })
            .catch(() => {
                console.log("failure");
            })

        firebaseSet(restaurantCustomersRef, null)
            .then(() => {
                console.log("success!");
            })
            .catch(() => {
                console.log("failure");
            })

        props.setIsDeleteActivated(!props.isDeleteActivited);
    }

    const onReject = () => {
        props.setIsDeleteActivated(!props.isDeleteActivited);
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        {
                            isUnapproved ? (
                                <motion.div
                                    className='delete-window'
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: 0,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    }}
                                >
                                    <div>You still have unapproved rewards with this restaurant. Please wait for restaurant to approve them before deleting your membership!</div>
                                    <div>
                                        <button id='no-dont-delete-membership-button' onClick={onReject}> Close </button>
                                    </div>

                                </motion.div>
                            ) : (
                                <motion.div
                                    className='delete-window'
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: 0,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    }}
                                >
                                    <div>Clicking 'Confirm' will delete all your points with {props.deleteRestaurant.name}. Are you sure?</div>
                                    <div>
                                        <button id='yes-delete-membership-button' onClick={onConfirm}> Confirm </button>
                                        <button id='no-dont-delete-membership-button' onClick={onReject}> No </button>
                                    </div>
                                </motion.div>
                            )
                        }
                    </>
                )
            }
        </>
    )
}