import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { auth } from './../../src/index';
import { Bounce, ToastContainer, toast } from 'react-toastify';

export function Sidebar(props) {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        }
    }
    return (
        <>
            <motion.nav
                className='sidebar'
                initial={{ x: 0 }}
                animate={{ x: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 120
                }}
            >

                <div className='sidebar-item'>
                    <button id='close-sidebar-button' onClick={props.handler}>
                        {/* <img src={require('./../imgs/close-icon.png')} alt='close' id='close-icon' /> */}
                        <p id='close-icon'>X</p>
                    </button>
                </div>

                <div className='sidebar-item'>
                    <ul>
                        <li><Link to='/contact-us'>Contact Us</Link></li>
                    </ul>
                </div>

                <div className='sidebar-item'>
                    <button id='log-out-button' onClick={handleLogout}>Log Out</button>
                </div>
            </motion.nav>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}