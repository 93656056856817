import React from "react";
import { Button } from './Button';
import { NavLink } from "react-router-dom";

export function SignUpContainer() {
    return (
        <>
            <div className='sign-up-container column-flex'>
                <div className='sign-up-item'>
                    <p>Sign Up!</p>
                </div>

                <div className='sign-up-item'>
                    <NavLink to='/restaurant-sign-up'> <Button id='restaurant-sign-up-button' text='Business'/> </NavLink>
                </div>

                <div className='sign-up-item'>
                    <NavLink to='/customer-sign-up'> <Button id='customer-sign-up-button' text='Customer'/> </NavLink>
                </div>
            </div>
        </>
    )
}