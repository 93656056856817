import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from "firebase/database";

export function Validate(props) {
    const [restaurantVerification, setRestaurantVerification] = useState();
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const restaurantRef = ref(db, "users/" + props.currentUser.uid);

            const unregisterFunction = onValue(restaurantRef, (snap) => {
                setData(snap.val());
                setLoader(false);
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.currentUser.uid])

    const setData = (data) => {
        setRestaurantVerification(data);
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    restaurantVerification.isRestaurant ? 
                        (restaurantVerification.isFirstLogin ? 
                            <Navigate to='/set-program' /> : <Navigate to='/my-restaurant' />) : 
                        (<Navigate to='/your-restaurants' />)
                )
            }
        </>
    )
}