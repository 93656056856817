import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "./../../src/index";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { getDatabase, ref, set as firebaseSet, onValue } from "firebase/database";

export function RestaurantSignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [restaurantName, setRestaurantName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const [numberOfRestaurants, setNumberOfRestaurants] = useState(0);
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const restaurantsRef = ref(db, "restaurants");

            const unregisterFunction = onValue(restaurantsRef, (snap) => {
                if (snap.exists()) {
                    const allRestaurantsObjects = snap.val();
                    const length = Object.keys(allRestaurantsObjects).length;
                    setData(length);
                    setLoader(false);
                } else {
                    setData(0);
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable])

    const setData = (data) => {
        setNumberOfRestaurants(data);
    }

    const handleRegister = async (e) => {
        e.preventDefault();

        const db = getDatabase();

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;

            const restaurantsRef = ref(db, "restaurants/" + user.uid);
            const usersRef = ref(db, "users/" + user.uid);

            if (user) {
                firebaseSet(restaurantsRef, {
                    "resID": "res" + parseInt(numberOfRestaurants + 1),
                    "ownerFName": fName,
                    "ownerLName": lName,
                    "email": email,
                    "restaurantName": restaurantName,
                    "ownerMobileNumber": mobileNumber,
                    "maxPoints": parseInt(-1),
                    "reward": ""
                })

                firebaseSet(usersRef, {
                    "isRestaurant": true,
                    "isFirstLogin": true
                })
            }

            toast.success("Signing you in now...", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });

            await auth.signOut();

            setEmail("");
            setFName("");
            setLName("");
            setMobileNumber("");
            setPassword("");
            setRestaurantName("");

            navigate('/sign-up-success', { state: "from sign up" })
        }

        catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            })
        }
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        <main className='sign-up-form-container scrollable'>
                            <div className='sign-up-form-item'>
                                <h3 className='sign-up-text'>BEHEER for <span className='color-red'>Restauranteurs!</span></h3>
                            </div>

                            <div className='sign-up-form-item'>
                                <form id='restaurant-sign-up-form' onSubmit={handleRegister}>
                                    <div className='restaurant-form-input-container'>
                                        <label className='visually-hidden' htmlFor='owner-first-name'>First Name:</label>
                                        <input
                                            type='text'
                                            id='owner-first-name'
                                            name='owner-first-name'
                                            placeholder='First Name'
                                            required
                                            value={fName}
                                            onChange={(e) => setFName(e.target.value)}
                                        />
                                    </div>

                                    <div className='restaurant-form-input-container'>
                                        <label className='visually-hidden' htmlFor='owner-last-name'>Last Name:</label>
                                        <input
                                            type='text'
                                            id='owner-last-name'
                                            name='owner-last-name'
                                            placeholder='Last Name'
                                            required
                                            value={lName}
                                            onChange={(e) => setLName(e.target.value)}
                                        />
                                    </div>

                                    <div className='restaurant-form-input-container'>
                                        <label className='visually-hidden' htmlFor='restaurant-name'>Restaurant Name:</label>
                                        <input
                                            type='text'
                                            id='restaurant-name'
                                            name='restaurant-name'
                                            placeholder='Restaurant Name'
                                            required
                                            value={restaurantName}
                                            onChange={(e) => setRestaurantName(e.target.value)}
                                        />
                                    </div>

                                    <div className='restaurant-form-input-container'>
                                        <label className='visually-hidden' htmlFor='owner-mobile-number'>Mobile Number:</label>
                                        <input
                                            type='tel'
                                            id='owner-mobile-number'
                                            name='owner-mobile-number'
                                            placeholder='Mobile Number'
                                            required
                                            value={mobileNumber}
                                            onChange={(e) => setMobileNumber(e.target.value)}
                                        />
                                    </div>

                                    <div className='restaurant-form-input-container'>
                                        <label className='visually-hidden' htmlFor='owner-email-address'>Email Address:</label>
                                        <input
                                            type='email'
                                            id='owner-email-address'
                                            name='owner-email-address'
                                            placeholder='Email Address'
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div className='restaurant-form-input-container'>
                                        <label className='visually-hidden' htmlFor='owner-password'>Password:</label>
                                        <input
                                            type='password'
                                            id='owner-password'
                                            name='owner-password'
                                            placeholder='Password'
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className='restaurant-form-input-container'>
                                        <button id='register-restaurant-button'>Sign Up!</button>
                                    </div>
                                </form>

                            </div>

                            <div className='sign-up-form-item'>
                                <NavLink to='/login'><p>Already a member? Sign in here!</p></NavLink>
                            </div>
                        </main>


                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                    </>
                )
            }
        </>
    )
}