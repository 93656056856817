import React from "react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { NavLink } from "react-router-dom";

export function LandingPageSidebar(props) {
    return (
        <>
            <motion.nav
                className='landing-page-sidebar'
                initial={{ x: 0 }}
                animate={{ x: 0 }}
                transition={{ type: 'spring', stiffness: 120 }}>

                <div className='landing-page-sidebar-item'>
                    <button id='close-landing-page-sidebar-button' onClick={props.handler}>
                        {/* <img src={require('./../imgs/close-icon.png')} alt='close' id='close-icon' /> */}
                        <p id='close-icon'>X</p>
                    </button>
                </div>

                <div className='landing-page-sidebar-item'>
                    <ul>
                        <li><Link to='/solutions'>Solutions</Link></li>
                        <li><Link to='/pricing'>Pricing</Link></li>
                        <li><Link to='/contact-us'>Contact Us</Link></li>
                        <li><Link to='/follow-us'>Follow Us</Link></li>
                    </ul>
                </div>

                <div className='landing-page-sidebar-item'>
                        <NavLink to='/login'><button id='sign-in-button'>Sign In</button></NavLink> 
                </div>

                <div className='landing-page-sidebar-item'>
                    <p>&copy; 2024 Beheer</p>
                </div>
            </motion.nav>
        </>
    )
}