import React, { useState } from "react";
import { LandingPageSidebar } from "./LandingPageSidebar";

export function LandingPageMobileHeader() {
    const [isLandingPageSidebarOpen, setIsLandingPageSidebarOpen] = useState(false);

    const toggleLandingPageSidebar = () => {
        setIsLandingPageSidebarOpen(!isLandingPageSidebarOpen);
    }

    return (
        <>
            <header className='mobile-header-container'>
                <div className='mobile-header-item'>

                </div>

                <div className='mobile-header-item'>
                    {/* <p className="logo">BEHEER</p> */}
                    <img src={require('./../imgs/beheerLogo.png')} alt='forward arrow' id='logo' />
                </div>

                <div className='mobile-header-item'>
                    <button id='toggle-button' onClick={toggleLandingPageSidebar}>
                        <img src={require('./../imgs/hamburgerIcon.png')} alt='menu' id='hamburger-menu' />
                    </button>
                </div>

                {isLandingPageSidebarOpen && <LandingPageSidebar handler={toggleLandingPageSidebar} />}
            </header>
        </>
    )
}