import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";

export function RewardHistory() {
    const location = useLocation();
    const dataFromCustomerPoints = location.state;

    const [rewards, setRewards] = useState();

    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    let rewardHistoryTabs = [];
    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const customerRewardsRef = ref(db, "customerRewards/" + dataFromCustomerPoints.customerKey + "/" + dataFromCustomerPoints.restaurantKey);

            const unregisterFunction = onValue(customerRewardsRef, (snap) => {
                if (snap.exists()) {
                    const allRewardsObjects = snap.val();

                    const allRewardKeys = Object.keys(allRewardsObjects);

                    const allRewardsArray = allRewardKeys.map((key) => {
                        const singleRewardCopy = { ...allRewardsObjects[key] };
                        singleRewardCopy.key = key;
                        return singleRewardCopy;
                    })

                    const allApprovedRewardsArray = allRewardsArray.filter((reward) => {
                        if (reward.hasBeenApproved === true) {
                            return true
                        } else {
                            return false
                        }
                    })

                    setData(allApprovedRewardsArray);
                    setLoader(false);
                } else {
                    setData([]);
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, dataFromCustomerPoints.customerKey, dataFromCustomerPoints.restaurantKey])

    const setData = (data) => {
        console.log(data);
        setRewards(data);
    }

    const getTabs = () => {
        rewardHistoryTabs = (rewards.map((reward, index) => {
            return <RewardHistoryTab
                key={reward.key}
                customerID={dataFromCustomerPoints.customerID}
                index={index + 1}
                claimDate={reward.claimDate}
                approvalDate={reward.approvalDate}
            />
        }))

        rewardHistoryTabs.reverse();
    }
    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        {
                            getTabs()
                        }
                        {
                            rewards.length > 0 ?
                                (
                                    <main className='reward-history-main'>
                                        <div className='reward-history-container'>
                                            <div className='reward-history-item'>
                                                <h1 className='heading'>Your reward history at</h1>
                                                <h1 className='heading'>{dataFromCustomerPoints.restaurantName}</h1>
                                                <h1 className='desktop-heading'>Your reward history at <span className='color-red'>{dataFromCustomerPoints.restaurantName}</span></h1>
                                            </div>

                                            <div className='reward-history-item scrollable'>
                                                <div className='reward-history-tab labels'>
                                                    <div className='reward-history-tab-item'>
                                                        <p>Sr. No.</p>
                                                    </div>
                                                    <div className='reward-history-tab-item'>
                                                        <p>Claim Date</p>
                                                    </div>
                                                    <div className='reward-history-tab-item'>
                                                        <p>Approval Date</p>
                                                    </div>
                                                </div>
                                                {
                                                    rewardHistoryTabs
                                                }
                                            </div>
                                        </div>

                                        <div className='back-button-container'>
                                            <NavLink to={`/your-restaurants/${dataFromCustomerPoints.restaurantName}`}> <button id='back-button'>Back</button></NavLink>
                                        </div>
                                    </main>
                                ) : (
                                    <main className='no-rewards-container'>
                                        <div className='no-rewards-item color-red'>
                                            No rewards to show right now
                                        </div>

                                        <div className='back-button-container'>
                                            <NavLink to={`/your-restaurants/${dataFromCustomerPoints.restaurantName}`}> <button id='back-button'>Back</button></NavLink>
                                        </div>
                                    </main>
                                )
                        }
                    </>
                )
            }
        </>
    )
}

function RewardHistoryTab(props) {
    return (
        <div className='reward-history-tab'>
            <div className='reward-history-tab-item'>
                <p>{props.index}</p>
            </div>
            <div className='reward-history-tab-item'>
                <p>{props.claimDate}</p>
            </div>
            <div className='reward-history-tab-item'>
                <p>{props.approvalDate}</p>
            </div>
        </div>
    )
}