import React, { useState } from "react";

export function SearchBar(props) {
    const [input, setInput] = useState("");

    const fetchData = (value) => {
        
        const results = props.restaurants.filter((restaurant) => {
            return (
                value &&
                restaurant &&
                restaurant.name &&
                restaurant.name.toLowerCase().includes(value)
            );
        });
        if (value && results.length === 0) {
            props.setResults([{ "name": "No results found" }]);
        }
        else {
            props.setResults(results);
        }

    }

    const handleChange = (value) => {
        setInput(value);
        fetchData(value.toLowerCase());
    }

    return (
        <>
            <input
                type='text'
                id='search'
                name='search'
                autoComplete="off"
                placeholder='Search...'
                value={input}
                onChange={(event) => handleChange(event.target.value)}
            />
        </>
    )
}