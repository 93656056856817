import React, { useState, useEffect } from "react";
import { Button } from './Button';
import { NavLink } from "react-router-dom";
import { getDatabase, ref, set as firebaseSet, onValue, push as firebasePush } from "firebase/database";

export function CustomerPointsNoFooterMain(props) {
    const [hasBeenClaimed, setHasBeenClaimed] = useState(false);
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    const dataToPassToRewardHistory = {
        customerKey: props.customerKey,
        customerID: props.customerID,
        restaurantKey: props.restaurantKey,
        restaurantName: props.name
    }
    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const rewardsRef = ref(db, "unapprovedRewards/" + props.restaurantKey + "/" + props.customerKey);

            const unregisterFunction = onValue(rewardsRef, (snap) => {
                if (snap.exists()) {
                    setData(true);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.customerKey, props.restaurantKey])

    const setData = (data) => {
        setHasBeenClaimed(data);
    }

    const handleClaim = () => {
        const db = getDatabase();
        const today = new Date();
        const date = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const claimDate = date + "/" + month + "/" + year;

        const unapprovedRewardsRef = ref(db, "unapprovedRewards/" + props.restaurantKey + "/" + props.customerKey);
        const customerRewardsRef = ref(db, "customerRewards/" + props.customerKey + "/" + props.restaurantKey);

        const newRewardKey = firebasePush(customerRewardsRef).key;
        firebaseSet(unapprovedRewardsRef, {
            "cusNumber": props.customerID,
            "points": props.points,
            "maxPoints": props.maxPoints,
            "rewardID": newRewardKey,
            "claimDate": claimDate
        });

        const customerNewRewardRef = ref(db, "customerRewards/" + props.customerKey + "/" + props.restaurantKey + "/" + newRewardKey);
        firebaseSet(customerNewRewardRef, {
            "hasBeenApproved": false,
            "claimDate": claimDate
        })
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <main className='no-footer-main-container'>
                        <div className='no-footer-main-item'>
                            <h1 className='heading'>Your favorite</h1>
                            <h1 className='heading'>{props.name}</h1>
                            <h1 className='desktop-heading'>Your favorite <span className='color-red'>{props.name}</span></h1>
                        </div>

                        <div className='no-footer-main-item'>
                            <div className='points-container column-flex'>
                                <div className='points-item'>
                                    <p>Customer ID: <span className='color-red'>{props.customerID}</span></p>
                                </div>

                                <div className='points-item'>
                                    <p className='color-red'>{props.points}/{props.maxPoints}</p>
                                </div>

                                <div className='points-item'>
                                    {(props.points - props.maxPoints) >= 0 ? hasBeenClaimed ?
                                        (
                                            <p className='approval-pending-text'>Waiting for restaurant to approve your reward...</p>
                                        ) : (
                                            <button id="claim-reward-button" onClick={handleClaim}>Claim Reward</button>
                                        )
                                        : (
                                            <p className='reward-progress-text'><span className='color-red'>{props.maxPoints - props.points}</span> points until next reward</p>
                                        )}
                                </div>
                            </div>

                            <div className='reward-history-button-container'>
                                <NavLink 
                                    to={`/your-restaurants/${props.name}/reward-history`}
                                    state={dataToPassToRewardHistory}
                                >
                                    <button id='reward-history-button'>Reward History</button>
                                </NavLink>
                            </div>
                        
                            <div className='back-button-container'>
                                <NavLink to='/your-restaurants'> <Button id='back-button' text='Back' /> </NavLink>
                            </div>
                        </div>
                    </main>
                )
            }
        </>
    )
}