import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Button } from "./Button";
import { SearchBar } from "./SearchBar";
import { SearchResultList } from "./SearchResultList";
import { NavLink } from "react-router-dom";
import { DeleteWindow } from "./DeleteWindow";

export function CustomerIndexNoFooterMain(props) {
    const [results, setResults] = useState([]);
    const [isDeleteActivited, setIsDeleteActivated] = useState(false);
    const [deleteRestaurant, setDeleteRestaurant] = useState();

    let restaurants = props.restaurants || [];

    let restaurantTabs = restaurants.map((restaurant) => {
        return <RestaurantTab
            key={restaurant.key}
            restaurant={restaurant}
            isDeleteActivited={isDeleteActivited}
            setIsDeleteActivated={setIsDeleteActivated}
            setDeleteRestaurant={setDeleteRestaurant}
        />
    })

    let restaurantKeys = restaurants.map((restaurant) => {
        return restaurant.resID
    })

    return (
        <>
            <main className='no-footer-main-container scrollable'>
                <div className='no-footer-main-item'>
                    <h1 className='heading'>Your favorite</h1>
                    <h1 className='heading'>Restaurants</h1>
                    <h1 className='desktop-heading'>Your favorite <span className='color-red'>Restaurants</span></h1>
                </div>

                <div className='no-footer-main-item column-flex' aria-disabled={isDeleteActivited}>
                    <div className='no-footer-main-subitem'>
                        <div className='search-container'>
                            <SearchBar setResults={setResults} restaurants={props.restaurants} />
                            {results && results.length > 0 && <SearchResultList results={results} />}
                        </div>
                    </div>

                    <div className='no-footer-main-subitem column-flex'>
                        <NavLink to='/add-restaurant' state={{ userRestaurants: restaurantKeys}}> <Button id='add-restaurant-button' text='Add Restaurant' /> </NavLink>
                    </div>

                    <div className='no-footer-main-subitem'>
                        {restaurants.length > 0 ? restaurantTabs : <div className='text-align-center'>No restaurants in your list. Add restaurant to get started!</div>}
                    </div>
                </div>

                {isDeleteActivited && <DeleteWindow
                    isDeleteActivited={isDeleteActivited}
                    setIsDeleteActivated={setIsDeleteActivated}
                    deleteRestaurant={deleteRestaurant}
                    customerID={props.currentCustomerID}
                />
                }
            </main>
        </>
    )
}

function RestaurantTab(props) {
    let restaurant = props.restaurant;

    return (
        <div className='restaurant-tab'>
            <div className='restaurant-tab-item'>
                <button id='delete-button' onClick={() => {
                    props.setIsDeleteActivated(!props.isDeleteActivited)
                    props.setDeleteRestaurant(restaurant)
                }}>
                    <img src={require('./../imgs/dustbin-icon.png')} alt='delete' id='dustbin-icon' />
                </button>
            </div>
            <div className='restaurant-tab-item'>
                <p>{restaurant.name}</p>
            </div>

            <div className='restaurant-tab-item'>
                <Link to={`/your-restaurants/${restaurant.name}`}>
                    <img src={require('./../imgs/arrowIcon.png')} alt='forward arrow' id='arrow' />
                </Link>
            </div>
        </div>
    )
}