import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { MobileHeader } from "./MobileHeader";
import { DesktopHeader } from "./DesktopHeader";
import { CustomerPointsNoFooterMain } from "./CustomerPointsNoFooterMain";
import { useOutletContext } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";

export function CustomerPoints(props) {

    const [customerID, setCustomerID] = useState("");
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const customerRef = ref(db, "customers/" + props.customerID + "/cusID");

            const unregisterFunction = onValue(customerRef, (snap) => {
                if (snap.exists()) {
                    setData(snap.val());
                    setLoader(false);
                } else {
                    setData("");
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.customerID])

    const setData = (data) => {
        setCustomerID(data);
    }

    const urlParams = useParams();
    const restaurantNameString = urlParams.restaurantName;

    const context = useOutletContext();

    let restaurant = _.find(context.customerRestaurantsData, { name: restaurantNameString });

    if (!restaurant) return <h2>Restaurant not found</h2>

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        <MobileHeader />
                        <DesktopHeader />
                        <CustomerPointsNoFooterMain restaurantKey={restaurant.key} name={restaurant.name} points={restaurant.points} reward={restaurant.reward} maxPoints={restaurant.maxPoints} customerKey={props.customerID} customerID={customerID} />
                    </>
                )
            }
        </>
    )
}