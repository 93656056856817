import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCPoekYfGaoJTe2Sz-kqrt9cmtJswQ8Zxw",
  authDomain: "beheer-b58c7.firebaseapp.com",
  projectId: "beheer-b58c7",
  storageBucket: "beheer-b58c7.appspot.com",
  messagingSenderId: "548747561653",
  appId: "1:548747561653:web:b574444cc8af308d504cfc",
  measurementId: "G-2EG877L9GR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

export const auth = getAuth();
export default app;