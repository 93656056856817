import React from "react";
import { NavLink, Navigate, useLocation } from "react-router-dom";

export function SignUpSuccess() {
    const location = useLocation();

    return (
        <>
            {
                location.state === "from sign up" ? (
                    <>
                        <main className='sign-up-success-main'>
                            <div className='sign-up-success-container'>
                                <div className='sign-up-success-item'>
                                    <h3 className="sign-up-text color-red">You have been signed up successfully!!!</h3>
                                </div>

                                <div className='sign-up-success-item'>
                                    <p>Get started immediately by using the "Sign in now" link below!</p>
                                    <p>Or go to "Home" if you wish to continue later...</p>
                                </div>

                                <div className='sign-up-success-item'>
                                    <NavLink to='/'><p>Home</p></NavLink>
                                    <NavLink to='/login'><p>Sign in now</p></NavLink>
                                </div>
                            </div>
                        </main>
                    </>
                ) : (
                    <Navigate to='/' />
                )
            }
        </>
    )
}