import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, set as firebaseSet } from "firebase/database";
import { Bounce, ToastContainer, toast } from 'react-toastify';

export function SetLoyaltyProgram(props) {
    const [maxPoints, setMaxPoints] = useState("");
    const [reward, setReward] = useState("");

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const db = getDatabase();

        const restaurantMaxPointsRef = ref(db, "restaurants/" + props.currentRestaurant.uid + "/maxPoints");
        const restaurantRewardRef = ref(db, "restaurants/" + props.currentRestaurant.uid + "/reward");
        const isFirstLoginRef = ref(db, "users/" + props.currentRestaurant.uid + "/isFirstLogin");

        firebaseSet(restaurantMaxPointsRef, parseInt(maxPoints));
        firebaseSet(restaurantRewardRef, reward);
        firebaseSet(isFirstLoginRef, false);

        setMaxPoints("");
        setReward("");

        toast.success("Successfully created your loyalty program!!!", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        });

        setTimeout(() => {
            navigate('/my-restaurant');
        }, 1000)
    }

    return (
        <>
            <main className='loyalty-form-container scrollable'>
                <div className='loyalty-form-item'>
                    <h3 className='sign-up-text'>Create your own <span className='color-red'>loyalty program!</span></h3>
                </div>

                <div className='loyalty-form-item'>
                    <form id='loyalty-form' onSubmit={handleSubmit}>
                        <div className='loyalty-form-input-super-container'>
                            <div className='loyalty-form-input-container'>
                                <label className='visually-hidden' htmlFor='reward'>Reward:</label>
                                <input
                                    type='text'
                                    id='reward'
                                    name='reward'
                                    placeholder='Reward'
                                    required
                                    value={reward}
                                    onChange={(e) => setReward(e.target.value)}
                                />
                            </div>
                            <p className='extra-information color-red'>E.g. "Free Pizza" or "10% Off"</p>
                        </div>

                        <div className='loyalty-form-input-super-container'>
                            <div className='loyalty-form-input-container'>
                                <label className='visually-hidden' htmlFor='max-points'>Points Needed for Reward:</label>
                                <input
                                    type='number'
                                    id='max-points'
                                    name='max-points'
                                    placeholder='Points Needed for Reward'
                                    required
                                    value={maxPoints}
                                    onChange={(e) => setMaxPoints(e.target.value)}
                                />
                            </div>
                            <p className='extra-information color-red'>E.g. "100"</p>
                        </div>

                        <div className='loyalty-form-input-super-container'>
                            <div className='loyalty-form-input-container'>
                                <button id='create-program-button' type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}