import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, set as firebaseSet } from "firebase/database";
import { NavLink } from "react-router-dom";

export function ApproveRewards(props) {
    const [unapprovedRewards, setUnapprovedRewards] = useState();

    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    let rewardTabs = [];
    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const unapprovedRewardsRef = ref(db, "unapprovedRewards/" + props.currentRestaurant.uid);

            const unregisterFunction = onValue(unapprovedRewardsRef, async (snap) => {
                if (snap.exists()) {
                    const allUnapprovedRewardsObjects = await snap.val();

                    const allUnapprovedRewardsKeys = Object.keys(allUnapprovedRewardsObjects);

                    const allUnapprovedRewardsArray = allUnapprovedRewardsKeys.map((key) => {
                        const singleUnapprovedRewardCopy = { ...allUnapprovedRewardsObjects[key] }
                        singleUnapprovedRewardCopy.key = key;
                        return singleUnapprovedRewardCopy
                    })
                    setData(allUnapprovedRewardsArray);
                    setLoader(false);
                } else {
                    setData([]);
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.currentRestaurant.uid])

    const setData = (data) => {
        setUnapprovedRewards(data);
    }

    const handleAllApproval = () => {
        const db = getDatabase();
        console.log(unapprovedRewards);
        const today = new Date();
        const date = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const approvalDate = date + "/" + month + "/" + year;

        unapprovedRewards.forEach((reward) => {

            const customerRewardsRef = ref(db, "customerRewards/" + reward.key + "/" + props.currentRestaurant.uid + "/" + reward.rewardID);
            firebaseSet(customerRewardsRef, {
                "hasBeenApproved": true,
                "claimDate": reward.claimDate,
                "approvalDate": approvalDate
            });

            const restaurantRewardsRef = ref(db, "restaurantRewards/" + props.currentRestaurant.uid + "/" + reward.key + "/" + reward.rewardID);
            firebaseSet(restaurantRewardsRef, {
                "claimDate": reward.claimDate,
                "approvalDate": approvalDate
            })

            const customerRestaurantsRef = ref(db, "customerRestaurants/" + reward.key + "/" + props.currentRestaurant.uid + "/points");
            firebaseSet(customerRestaurantsRef, parseInt(reward.points) - parseInt(reward.maxPoints))

            const restaurantCustomersRef = ref(db, "restaurantCustomers/" + props.currentRestaurant.uid + "/" + reward.key + "/points");
            firebaseSet(restaurantCustomersRef, parseInt(reward.points) - parseInt(reward.maxPoints))
        })
        const unapprovedRewardsRef = ref(db, "unapprovedRewards/" + props.currentRestaurant.uid);
        firebaseSet(unapprovedRewardsRef, null);
    }

    const getTabs = () => {
        rewardTabs = (unapprovedRewards.map((reward) => {
            return <RewardTab
                key={reward.key}
                customerID={reward.key}
                cusNumber={reward.cusNumber}
                claimDate={reward.claimDate}
                points={reward.points}
                maxPoints={reward.maxPoints}
                rewardID={reward.rewardID}
                restaurantID={props.currentRestaurant.uid}
            />
        }))
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        {
                            getTabs()
                        }
                        {
                            unapprovedRewards.length > 0 ?
                                (
                                    <main className='approve-rewards-main'>
                                        <div className='approve-rewards-container'>
                                            <div className='approve-rewards-item'>
                                                <h1 className='heading'>Approve rewards for your</h1>
                                                <h1 className='heading'>Customers</h1>
                                                <h1 className='desktop-heading'>Approve rewards for your <span className='color-red'>Customers</span></h1>
                                            </div>

                                            <div className='approve-rewards-item scrollable'>
                                                <button id='approve-all-rewards' onClick={handleAllApproval}>Approve All</button>
                                                {
                                                    rewardTabs
                                                }
                                            </div>
                                        </div>

                                        <div className='back-button-container'>
                                            <NavLink to='/my-restaurant'><button id='back-button'>Back</button></NavLink>
                                        </div>
                                    </main>
                                ) :
                                (
                                    <main className='no-rewards-container'>
                                        <div className='no-rewards-item color-red'>
                                            No rewards to approve right now
                                        </div>

                                        <div className='back-button-container'>
                                            <NavLink to='/my-restaurant'><button id='back-button'>Back</button></NavLink>
                                        </div>
                                    </main>
                                )
                        }
                    </>
                )
            }
        </>
    )
}

function RewardTab(props) {
    const handleSingleApproval = () => {
        const db = getDatabase();

        const today = new Date();
        const date = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const approvalDate = date + "/" + month + "/" + year;

        const unapprovedRewardsRef = ref(db, "unapprovedRewards/" + props.restaurantID + "/" + props.customerID);
        firebaseSet(unapprovedRewardsRef, null);

        const customerRewardsRef = ref(db, "customerRewards/" + props.customerID + "/" + props.restaurantID + "/" + props.rewardID);
        firebaseSet(customerRewardsRef, {
            "hasBeenApproved": true,
            "claimDate": props.claimDate,
            "approvalDate": approvalDate
        });

        const restaurantRewardsRef = ref(db, "restaurantRewards/" + props.restaurantID + "/" + props.customerID + "/" + props.rewardID);
        firebaseSet(restaurantRewardsRef, {
            "claimDate": props.claimDate,
            "approvalDate": approvalDate
        })

        const customerRestaurantsRef = ref(db, "customerRestaurants/" + props.customerID + "/" + props.restaurantID + "/points");
        firebaseSet(customerRestaurantsRef, parseInt(props.points) - parseInt(props.maxPoints))

        const restaurantCustomersRef = ref(db, "restaurantCustomers/" + props.restaurantID + "/" + props.customerID + "/points");
        firebaseSet(restaurantCustomersRef, parseInt(props.points) - parseInt(props.maxPoints))
    }
    return (
        <div className='reward-tab'>
            <div className='reward-tab-item color-red'>
                <p>{props.cusNumber}</p>
            </div>

            <div className='reward-tab-item'>
                <p>{props.claimDate}</p>
            </div>

            <div className='reward-tab-item'>
                <button id='approve-single-reward' onClick={handleSingleApproval}>Approve</button>
            </div>
        </div>
    )
}