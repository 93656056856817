import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./../../src/index";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { getDatabase, ref, onValue } from "firebase/database";

export function Login() {
    const [users, setUsers] = useState();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const usersRef = ref(db, "users");

            const unregisterFunction = onValue(usersRef, (snap) => {
                if (snap.exists()) {
                    const allUsersObjects = snap.val();

                    setData(allUsersObjects);
                    setLoader(false);
                } else {
                    setData({});
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable])

    const setData = (data) => {
        setUsers(data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await signInWithEmailAndPassword(auth, email, password);

            const userUID = auth.currentUser.uid;

            const userType = users[userUID].isRestaurant;
            const isFirstLogin = users[userUID].isFirstLogin;

            if (userType) {
                if (isFirstLogin) {
                    navigate('/set-program')
                } else {
                    navigate('/my-restaurant');
                }
            } else {
                navigate('/your-restaurants');
            }

        }
        catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        }
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        <main className='login-form-container scrollable'>
                            <div className='login-form-item'>
                                <h3 className='sign-up-text'>Log in to your <span className='color-red'>Beheer Account!</span></h3>
                            </div>

                            <div className='login-form-item'>
                                <form id='login-form' onSubmit={handleSubmit}>
                                    <div className='login-form-input-container'>
                                        <label className='visually-hidden' htmlFor='login-email'>Email Address:</label>
                                        <input
                                            type='email'
                                            id='login-email'
                                            name='login-email'
                                            placeholder='Email Address'
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div className='login-form-input-container'>
                                        <label className='visually-hidden' htmlFor='login-password'>Password:</label>
                                        <input
                                            type='password'
                                            id='login-password'
                                            name='login-password'
                                            placeholder='Password'
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className='login-form-input-container'>
                                        <button id='login-customer-button' type="submit">Log In!</button>
                                    </div>
                                </form>
                            </div>
                        </main>

                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                    </>
                )
            }
        </>
    )
}