import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { MobileHeader } from "./MobileHeader";
import { DesktopHeader } from "./DesktopHeader";
import { CustomerIndexNoFooterMain } from "./CustomerIndexNoFooterMain";
import { getDatabase, ref, onValue } from "firebase/database";

export function CustomerIndex(props) {
    const urlParams = useParams();
    const restaurantNameString = urlParams.restaurantName;

    const [customerRestaurantsData, setCustomerRestaurantsData] = useState();
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();
            const customerRestaurantsRef = ref(db, "customerRestaurants/" + props.currentCustomer.uid);

            const unregisterFunction = onValue(customerRestaurantsRef, (snap) => {
                if (snap.exists()) {
                    const allRestaurantsObjects = snap.val();

                    const allRestaurantsKeys = Object.keys(allRestaurantsObjects);

                    const allRestaurantsArray = allRestaurantsKeys.map((key) => {
                        const singleRestaurantCopy = { ...allRestaurantsObjects[key] };
                        singleRestaurantCopy.key = key;
                        return singleRestaurantCopy;
                    });

                    setData(allRestaurantsArray);
                    setLoader(false);
                } else {
                    setData([]);
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }


    }, [renderVariable, props.currentCustomer.uid])

    const setData = (data) => {
        setCustomerRestaurantsData(data);
    }


    if (restaurantNameString && customerRestaurantsData) {
        return (
            <>
                <Outlet context={{ customerRestaurantsData }} />
            </>
        )
    }

    return (
        <>
            <MobileHeader />
            <DesktopHeader />
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <CustomerIndexNoFooterMain restaurants={customerRestaurantsData} currentCustomerID={props.currentCustomer.uid} />
                )
            }
        </>
    )
}