import React from "react";
import { auth } from "./../../src/index";
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';

export function DesktopHeader() {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        }
    }

    return (
        <>
            <header className='desktop-header-container'>
                <div className='desktop-header-item'>
                    {/* <p className='logo'>BEHEER</p> */}
                    <img src={require('./../imgs/beheerLogo.png')} alt='forward arrow' id='logo' />
                </div>

                <div className='desktop-header-item'>
                    <nav className='desktop-navigation'>
                        <ul>
                            <li>Contact Us</li>
                        </ul>
                    </nav>
                </div>

                <div className='desktop-header-item'>
                    <button id='log-out-button' onClick={handleLogout}>Log Out</button>
                </div>
            </header>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}