import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatabase, ref, onValue, set as firebaseSet } from "firebase/database";

export function AddRestaurant(props) {
    const [restaurantID, setRestaurantID] = useState("");
    const [restaurantList, setRestaurantList] = useState();
    const [currentCustomer, setCurrentCustomer] = useState();
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    let location = useLocation();

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();
            const restaurantsRef = ref(db, "restaurants");

            const unregisterFunction = onValue(restaurantsRef, (snap) => {
                const allRestaurantsObjects = snap.val();

                const allRestaurantsKeys = Object.keys(allRestaurantsObjects);

                const allRestaurantsArray = allRestaurantsKeys.map((key) => {
                    const singleRestaurantCopy = { ...allRestaurantsObjects[key] };
                    singleRestaurantCopy.key = key;
                    return singleRestaurantCopy;
                });

                setData(allRestaurantsArray);

                const currentCustomerRef = ref(db, "customers/" + props.currentCustomer.uid);

                onValue(currentCustomerRef, (snap) => {
                    if (snap.exists()) {
                        setCustomerData(snap.val());
                    } else {
                        setCustomerData({});
                    }
                })

                setLoader(false);
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable, props.currentCustomer.uid])

    const setData = (data) => {
        setRestaurantList(data);
    }

    const setCustomerData = (data) => {
        setCurrentCustomer(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let isRestaurantID = false;

        for (let i = 0; i < restaurantList.length; i++) {
            if (restaurantList[i].resID === restaurantID.toLowerCase()) {
                isRestaurantID = true;
            }
        }

        if (!isRestaurantID) {
            toast.error('Please enter valid restaurant ID', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        }
        else {
            const db = getDatabase();

            let allCustomerRestaurantsKeys = location.state.userRestaurants

            let isRestaurantAlreadyInList = false;

            for (let i = 0; i < allCustomerRestaurantsKeys.length; i++) {
                if (allCustomerRestaurantsKeys[i] === restaurantID.toLowerCase()) {
                    isRestaurantAlreadyInList = true;
                }
            }

            if (isRestaurantAlreadyInList) {
                toast.error('You are already a member of this restaurant!!!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
            else {
                const desiredRestaurant = restaurantList.filter((restaurant) => {
                    return (
                        restaurantID.toLowerCase() &&
                        restaurant &&
                        restaurant.resID &&
                        restaurant.resID === restaurantID.toLowerCase()
                    )
                })

                const maxPointsRef = ref(db, "restaurants/" + desiredRestaurant[0].key + "/maxPoints");
                const restaurantNameRef = ref(db, "restaurants/" + desiredRestaurant[0].key + "/restaurantName");

                // const customerRestaurantsRef = ref(db, "customerRestaurants/" + props.currentCustomer.uid + "/" + desiredRestaurant[0].key);

                let maxPoints = 0;

                onValue(maxPointsRef, (snap) => {
                    maxPoints = snap.val();
                })

                let restaurantName = "";

                onValue(restaurantNameRef, (snap) => {
                    restaurantName = snap.val();
                })

                if (maxPoints === -1) {
                    toast.error('This restaurant has not created a loyalty program yet :(', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                } else {
                    const customerRestaurantsRef = ref(db, "customerRestaurants/" + props.currentCustomer.uid + "/" + desiredRestaurant[0].key);

                    firebaseSet(customerRestaurantsRef, {
                        "resID": restaurantID.toLowerCase(),
                        "maxPoints": maxPoints,
                        "name": restaurantName,
                        "points": 0
                    })

                    const restaurantCustomersRef = ref(db, "restaurantCustomers/" + desiredRestaurant[0].key + "/" + props.currentCustomer.uid);

                    firebaseSet(restaurantCustomersRef, {
                        "cusID": currentCustomer.cusID,
                        "points": 0
                    })

                    setRestaurantID("");

                    toast.success('Restaurant added successfully!!!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                }
            }
        }
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <div className='add-restaurant-container'>
                        <div className='add-restaurant-item'>
                            {/* <NavLink to='/your-restaurants'> <img src={require('./../imgs/backArrowIcon.png')} alt='back' id='back-icon' /> </NavLink> */}
                            <div className='add-restaurant-logo-container'>
                                <img src={require('./../imgs/beheerLogo.png')} alt='forward arrow' id='logo' />
                            </div>
                        </div>

                        <div className='add-restaurant-item'>
                            <div className='add-restaurant-subitem'>
                                <h1 className='heading'>Add</h1>
                                <h1 className='heading'>Restaurant</h1>
                                <h1 className='desktop-heading'>Add <span className='color-red'>Restaurant</span></h1>
                            </div>

                            <div className='add-restaurant-subitem'>
                                <form className='add-restaurant-form' autoComplete="off" onSubmit={handleSubmit}>
                                    <div className='add-restaurant-form-item'>
                                        <label className='visually-hidden' htmlFor='restaurant-id'>Restaurant ID:</label>
                                        <input
                                            type='text'
                                            id='restaurant-id'
                                            name='restaurant-id'
                                            placeholder='Restaurant ID'
                                            value={restaurantID}
                                            onChange={(e) => setRestaurantID(e.target.value)}
                                        />
                                    </div>

                                    <div className='add-restaurant-form-item'>
                                        <button id='final-add-restaurant-button' type="submit">Add</button>
                                    </div>
                                </form>
                                <div>
                                    <NavLink to='/your-restaurants'><button id='back-button'>Back</button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}