import React, { useState } from "react";
import { Sidebar } from "./Sidebar";

export function MobileHeader() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <>
            <header className='mobile-header-container'>
                <div className='mobile-header-item'>

                </div>

                <div className='mobile-header-item'>
                    {/* <p className="logo">BEHEER</p> */}
                    <img src={require('./../imgs/beheerLogo.png')} alt='forward arrow' id='logo' />
                </div>

                <div className='mobile-header-item'>
                    <button id='toggle-button' onClick={toggleSidebar}>
                        <img src={require('./../imgs/hamburgerIcon.png')} alt='menu' id='hamburger-menu' />
                    </button>
                </div>

                {isSidebarOpen && <Sidebar handler={toggleSidebar} />}
            </header>
        </>
    )
}