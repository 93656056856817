import React from "react";
import { LandingPageMobileHeader } from './LandingPageMobileHeader';
import { LandingPageDesktopHeader } from "./LandingPageDesktopHeader";
import { LandingPageNoFooterMain } from './LandingPageNoFooterMain';

export function LandingPage() {

    return (
        <>
            <LandingPageMobileHeader />
            <LandingPageDesktopHeader />
            <LandingPageNoFooterMain />
        </>
    )
}