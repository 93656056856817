import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { LandingPage } from './LandingPage';
import { CustomerIndex } from './CustomerIndex';
import { CustomerPoints } from './CustomerPoints';
import { RewardHistory } from "./RewardHistory";
import { RestaurantIndex } from './RestaurantIndex';
import { ApproveRewards } from "./ApproveRewards";
import { AddRestaurant } from "./AddRestaurant";
import { RestaurantSignUp } from "./RestaurantSignUp";
import { SetLoyaltyProgram } from "./SetLoyaltyProgram";
import { CustomerSignUp } from "./CustomerSignUp";
import { SignUpSuccess } from "./SignUpSuccess";
import { Login } from "./Login";
import { Validate } from "./Validate";

import './../index.css';

import { auth } from "./../index";

function App() {
    const [user, setUser] = useState();

    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            setUser(user);
        })
    })

    return (
        <>
            <div>
                <Routes>
                    <Route index element={user ? <Navigate to='/validate' /> : <LandingPage />} />
                    <Route path='/your-restaurants' element={user ? <CustomerIndex currentCustomer={user} /> : <Navigate to='/' />} >
                        <Route path='/your-restaurants/:restaurantName' element={user ? <CustomerPoints customerID={user.uid} /> : <Navigate to='/' />} />
                        <Route path='/your-restaurants/:restaurantName/reward-history' element={<RewardHistory />} />
                    </Route>
                    <Route path='/restaurant-sign-up' element={user ? <Navigate to='/' /> : <RestaurantSignUp />} />
                    <Route path='/set-program' element={user ? <SetLoyaltyProgram currentRestaurant={user} /> : <Navigate to='/' />} />
                    <Route path='/customer-sign-up' element={user ? <Navigate to='/' /> : <CustomerSignUp />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/sign-up-success' element={<SignUpSuccess />} />
                    <Route path='/validate' element={user ? <Validate currentUser={user} /> : <Navigate to='/' />} />
                    <Route path='/my-restaurant' element={user ? <RestaurantIndex currentRestaurant={user} /> : <Navigate to='/' />} />
                    <Route path="my-restaurant/approve-rewards" element={user ? <ApproveRewards currentRestaurant={user}/> : <Navigate to='/' />} />
                    <Route path='/add-restaurant' element={user ? <AddRestaurant currentCustomer={user} /> : <Navigate to='/' />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </div>
        </>
    )
}

export default App;