import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "./../../src/index";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { getDatabase, ref, set as firebaseSet, onValue } from "firebase/database";

export function CustomerSignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const [numberOfCustomers, setNumberOfCustomers] = useState(0);
    const [renderVariable, setRenderVariable] = useState(false);
    const [loader, setLoader] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (renderVariable) {
            const db = getDatabase();

            const customersRef = ref(db, "customers");

            const unregisterFunction = onValue(customersRef, (snap) => {
                if (snap.exists()) {
                    const allCustomersObjects = snap.val();
                    const length = Object.keys(allCustomersObjects).length;
                    setData(length);
                    setLoader(false);
                } else {
                    setData(0);
                    setLoader(false);
                }
            })

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
        else {
            const renderFunction = () => {
                setRenderVariable(!renderVariable);
            }

            renderFunction();

            const unregisterFunction = () => {
                renderFunction();
            }

            function cleanup() {
                unregisterFunction();
            }

            return cleanup;
        }
    }, [renderVariable])

    const setData = (data) => {
        setNumberOfCustomers(data);
    }

    const handleRegister = async (e) => {
        e.preventDefault();

        const db = getDatabase();

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;

            const customersRef = ref(db, "customers/" + user.uid);
            const usersRef = ref(db, "users/" + user.uid)

            if (user) {
                firebaseSet(customersRef, {
                    "cusID": "cus" + parseInt(numberOfCustomers + 1),
                    "fName": fName,
                    "lName": lName,
                    "email": email,
                    "mobileNumber": mobileNumber
                })

                firebaseSet(usersRef, {
                    "isRestaurant": false,
                    "isFirstLogin": false
                })
            }

            toast.success("Signing you up now...", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });

            await auth.signOut();

            setEmail("");
            setFName("");
            setLName("");
            setMobileNumber("");
            setPassword("");

            navigate('/sign-up-success', { state: "from sign up" })
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            })
        }
    }

    return (
        <>
            {
                loader ? (
                    <div className="loading-page">
                        <img src={require('./../imgs/loadingSpinner.svg').default} alt='loading' id='loading-spinner' />
                    </div>
                ) : (
                    <>
                        <main className='sign-up-form-container scrollable'>
                            <div className='sign-up-form-item'>
                                <h3 className='sign-up-text'>BEHEER for <span className='color-red'>Customers!</span></h3>
                            </div>

                            <div className='sign-up-form-item'>
                                <form id='customer-sign-up-form' onSubmit={handleRegister}>
                                    <div className='customer-form-input-container'>
                                        <label className='visually-hidden' htmlFor='customer-first-name'>First Name:</label>
                                        <input
                                            type='text'
                                            id='customer-first-name'
                                            name='customer-first-name'
                                            placeholder='First Name'
                                            required
                                            value={fName}
                                            onChange={(e) => setFName(e.target.value)}
                                        />
                                    </div>

                                    <div className='customer-form-input-container'>
                                        <label className='visually-hidden' htmlFor='customer-last-name'>Last Name:</label>
                                        <input
                                            type='text'
                                            id='customer-last-name'
                                            name='customer-last-name'
                                            placeholder='Last Name'
                                            required
                                            value={lName}
                                            onChange={(e) => setLName(e.target.value)}
                                        />
                                    </div>

                                    <div className='customer-form-input-container'>
                                        <label className='visually-hidden' htmlFor='customer-mobile-number'>Mobile Number:</label>
                                        <input
                                            type='tel'
                                            id='customer-mobile-number'
                                            name='customer-mobile-number'
                                            placeholder='Mobile Number'
                                            required
                                            value={mobileNumber}
                                            onChange={(e) => setMobileNumber(e.target.value)}
                                        />
                                    </div>

                                    <div className='customer-form-input-container'>
                                        <label className='visually-hidden' htmlFor='customer-email-address'>Email Address:</label>
                                        <input
                                            type='email'
                                            id='customer-email-address'
                                            name='customer-email-address'
                                            placeholder='Email Address'
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div className='customer-form-input-container'>
                                        <label className='visually-hidden' htmlFor='customer-password'>Password:</label>
                                        <input
                                            type='password'
                                            id='customer-password'
                                            name='customer-password'
                                            placeholder='Password'
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className='customer-form-input-container'>
                                        <button id='register-customer-button'>Sign Up!</button>
                                    </div>
                                </form>
                            </div>


                            <div className='sign-up-form-item'>
                                <NavLink to='/login'><p>Already a member? Sign in here!</p></NavLink>
                            </div>
                        </main>

                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                    </>
                )
            }
        </>
    )
}