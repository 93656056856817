import React from "react";
import { Link } from 'react-router-dom';

export function SearchResult(props) {
    return (
        <>
            <Link to={`/your-restaurants/${props.result}`}>
                <div className='search-result'>
                    {props.result}
                </div>
            </Link>
        </>
    )
}